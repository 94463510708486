import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { SmallHeader } from '../typography';
import { IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { MorningMeetsLogoSmall } from '../morning-meets-logo';

const PageFooter = () => {
	return (
		<Stack sx={{ alignItems: 'center' }} spacing={3} p={6}>
			<SmallHeader>Follow us on Social Media</SmallHeader>

			<Box>
				<IconButton
					onClick={() =>
						window.open('https://www.instagram.com/morningmeets/', '_blank')
					}
				>
					<InstagramIcon fontSize="large" color="primary" />
				</IconButton>
				<IconButton
					onClick={() =>
						window.open(
							'https://www.facebook.com/profile.php?id=61556194195096',
							'_blank'
						)
					}
				>
					<FacebookIcon fontSize="large" color="primary" />
				</IconButton>
				<IconButton
					onClick={() =>
						window.open(
							'https://www.linkedin.com/company/morning-meets/',
							'_blank'
						)
					}
				>
					<LinkedInIcon fontSize="large" color="primary" />
				</IconButton>
			</Box>

			<SmallHeader>contact@morningmeets.com</SmallHeader>

			<MorningMeetsLogoSmall />

			<Typography>Copyright © 2024, Morning Meets.</Typography>
		</Stack>
	);
};

export default PageFooter;
