import { Button, Stack, Box } from '@mui/material';

interface MembersTableActionsProps {
	onAddMemberClick: () => void;
	onBlockSelectedClick: () => void;
	selectedRows: Set<string>;
}

const MembersTableActions = ({
	onAddMemberClick,
	onBlockSelectedClick,
	selectedRows,
}: MembersTableActionsProps) => {
	function confirmBlockSelected() {
		onBlockSelectedClick();
	}

	function openAddMemberModal() {
		onAddMemberClick();
	}

	return (
		<Stack direction="row" spacing={3} sx={{ padding: 2 }}>
			<Stack spacing={1} sx={{ flex: '1 1 auto' }}>
				<Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
					{selectedRows.size > 0 && (
						<>
							<Button color="inherit" onClick={confirmBlockSelected}>
								Block selected
							</Button>
						</>
					)}
				</Stack>
			</Stack>

			<Box>
				<Button
					variant="contained"
					color="primary"
					onClick={openAddMemberModal}
				>
					Add member
				</Button>
			</Box>
		</Stack>
	);
};

export default MembersTableActions;
