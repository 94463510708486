import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
	Box,
	TextField,
	Button,
	Typography,
	Paper,
	Chip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import type { IVehicle } from '../../model/interfaces';
import Grid from '@mui/material/Grid2';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	width: 1,
});

const ApplicationForm = () => {
	const theme = useTheme();

	const [vehicles, setVehicles] = useState<IVehicle[]>([]);

	const [selectedVehicleIndex, setSelectedVehicleIndex] = useState<
		number | null
	>(null);
	const [currentVehicle, setCurrentVehicle] = useState<IVehicle>({
		brand: '',
		model: '',
		year: new Date().getFullYear(),
		color: '',
		plate: '',
	});

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		city: '',
		province: '',
		country: '',
		instagram: '',
		facebook: '',
		twitter: '',
	});

	const handleAddVehicle = () => {
		if (currentVehicle.brand && currentVehicle.model) {
			setVehicles([...vehicles, { ...currentVehicle }]);
			setCurrentVehicle({
				brand: '',
				model: '',
				year: new Date().getFullYear(),
				color: '',
				plate: '',
			});
		}
	};

	const handleRemoveVehicle = (index: number) => {
		setVehicles(vehicles.filter((_, i) => i !== index));
	};

	const handleEditVehicle = (index: number) => {
		setSelectedVehicleIndex(index);
	};

	const handleCloseDialog = () => {
		setSelectedVehicleIndex(null);
	};

	const handleUpdateVehicle = (
		index: number,
		field: keyof IVehicle,
		value: string | number
	) => {
		const updatedVehicles = [...vehicles];
		updatedVehicles[index] = { ...updatedVehicles[index], [field]: value };
		setVehicles(updatedVehicles);
	};

	const isFormValid = () => {
		const requiredFields = [
			formData.firstName,
			formData.lastName,
			formData.email,
			formData.phone,
			formData.city,
			formData.province,
			formData.country,
		];

		const hasRequiredFields = requiredFields.every(
			(field) => field.trim() !== ''
		);
		const hasVehicles = vehicles.length > 0;

		return hasRequiredFields && hasVehicles;
	};

	return (
		<Paper
			elevation={3}
			sx={{
				p: theme.spacing(4),
				display: 'flex',
				flexDirection: 'column',
				gap: theme.spacing(4),
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
				backdropFilter: 'blur(3px)',
			}}
		>
			<Box>
				<Typography variant="h6" gutterBottom>
					Personal Information
				</Typography>
				<Grid container spacing={2}>
					<Grid size={{ xs: 6 }}>
						<TextField
							fullWidth
							label="First Name"
							required
							value={formData.firstName}
							onChange={(e) =>
								setFormData({ ...formData, firstName: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<TextField
							fullWidth
							label="Last Name"
							required
							value={formData.lastName}
							onChange={(e) =>
								setFormData({ ...formData, lastName: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<TextField
							fullWidth
							label="Email"
							type="email"
							required
							value={formData.email}
							onChange={(e) =>
								setFormData({ ...formData, email: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 6 }}>
						<TextField
							fullWidth
							label="Phone"
							required
							value={formData.phone}
							onChange={(e) =>
								setFormData({ ...formData, phone: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 4 }}>
						<TextField
							fullWidth
							label="City"
							required
							value={formData.city}
							onChange={(e) =>
								setFormData({ ...formData, city: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 4 }}>
						<TextField
							fullWidth
							label="Province"
							required
							value={formData.province}
							onChange={(e) =>
								setFormData({ ...formData, province: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 4 }}>
						<TextField
							fullWidth
							label="Country"
							required
							value={formData.country}
							onChange={(e) =>
								setFormData({ ...formData, country: e.target.value })
							}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box>
				<Typography variant="h6" gutterBottom>
					Social Media
				</Typography>
				<Grid container spacing={2}>
					<Grid size={{ xs: 4 }}>
						<TextField
							fullWidth
							label="Instagram"
							value={formData.instagram}
							onChange={(e) =>
								setFormData({ ...formData, instagram: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 4 }}>
						<TextField
							fullWidth
							label="Facebook"
							value={formData.facebook}
							onChange={(e) =>
								setFormData({ ...formData, facebook: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 4 }}>
						<TextField
							fullWidth
							label="Twitter"
							value={formData.twitter}
							onChange={(e) =>
								setFormData({ ...formData, twitter: e.target.value })
							}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box>
				<Typography variant="h6" gutterBottom>
					Vehicles
				</Typography>
				<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
					{vehicles.map((vehicle, index) => (
						<Chip
							key={index}
							label={`${vehicle.brand} ${vehicle.model}`}
							onDelete={() => handleRemoveVehicle(index)}
							onClick={() => handleEditVehicle(index)}
							icon={<EditIcon />}
							sx={{ '& .MuiChip-icon': { ml: 1 } }}
						/>
					))}
				</Box>

				<Grid container spacing={2} alignItems="center" columns={15}>
					<Grid size={{ xs: 8, lg: 3 }}>
						<TextField
							fullWidth
							label="Brand"
							required
							value={currentVehicle.brand}
							onChange={(e) =>
								setCurrentVehicle({ ...currentVehicle, brand: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 7, lg: 3 }}>
						<TextField
							fullWidth
							label="Model"
							required
							value={currentVehicle.model}
							onChange={(e) =>
								setCurrentVehicle({ ...currentVehicle, model: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 5, lg: 3 }}>
						<TextField
							fullWidth
							label="Year"
							type="number"
							required
							value={currentVehicle.year}
							onChange={(e) =>
								setCurrentVehicle({
									...currentVehicle,
									year: parseInt(e.target.value),
								})
							}
							inputProps={{ min: 1900, max: new Date().getFullYear() }}
						/>
					</Grid>
					<Grid size={{ xs: 5, lg: 3 }}>
						<TextField
							fullWidth
							label="Color"
							required
							value={currentVehicle.color}
							onChange={(e) =>
								setCurrentVehicle({ ...currentVehicle, color: e.target.value })
							}
						/>
					</Grid>
					<Grid size={{ xs: 5, lg: 3 }}>
						<TextField
							fullWidth
							label="Plate"
							required
							value={currentVehicle.plate}
							onChange={(e) =>
								setCurrentVehicle({
									...currentVehicle,
									plate: e.target.value.toUpperCase(),
								})
							}
						/>
					</Grid>
				</Grid>

				<Box
					sx={{
						mt: 2,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}
				>
					<Box display="grid" gap={2}>
						<Typography variant="h6" component="span" sx={{ mr: 2 }}>
							Vehicle Images
						</Typography>
						<Button
							component="label"
							variant="outlined"
							startIcon={<CloudUploadIcon />}
						>
							Upload Images
							<VisuallyHiddenInput type="file" multiple accept="image/*" />
						</Button>
					</Box>

					<Button
						startIcon={<AddCircleOutlineIcon />}
						onClick={handleAddVehicle}
						disabled={
							!currentVehicle.brand ||
							!currentVehicle.model ||
							!currentVehicle.year ||
							!currentVehicle.color ||
							!currentVehicle.plate
						}
					>
						Add Vehicle
					</Button>
				</Box>

				<Dialog
					open={selectedVehicleIndex !== null}
					onClose={handleCloseDialog}
					maxWidth="md"
					fullWidth
				>
					<DialogTitle>Edit Vehicle</DialogTitle>
					<DialogContent>
						{selectedVehicleIndex !== null && (
							<Grid container spacing={2} sx={{ mt: 1 }}>
								<Grid size={{ xs: 6 }}>
									<TextField
										fullWidth
										label="Brand"
										required
										value={vehicles[selectedVehicleIndex].brand}
										onChange={(e) =>
											handleUpdateVehicle(
												selectedVehicleIndex,
												'brand',
												e.target.value
											)
										}
									/>
								</Grid>
								<Grid size={{ xs: 6 }}>
									<TextField
										fullWidth
										label="Model"
										required
										value={vehicles[selectedVehicleIndex].model}
										onChange={(e) =>
											handleUpdateVehicle(
												selectedVehicleIndex,
												'model',
												e.target.value
											)
										}
									/>
								</Grid>
								<Grid size={{ xs: 4 }}>
									<TextField
										fullWidth
										label="Year"
										type="number"
										required
										value={vehicles[selectedVehicleIndex]?.year}
										onChange={(e) =>
											handleUpdateVehicle(
												selectedVehicleIndex,
												'year',
												parseInt(e.target.value)
											)
										}
										inputProps={{ min: 1900, max: new Date().getFullYear() }}
									/>
								</Grid>
								<Grid size={{ xs: 4 }}>
									<TextField
										fullWidth
										label="Color"
										required
										value={vehicles[selectedVehicleIndex].color}
										onChange={(e) =>
											handleUpdateVehicle(
												selectedVehicleIndex,
												'color',
												e.target.value
											)
										}
									/>
								</Grid>
								<Grid size={{ xs: 4 }}>
									<TextField
										fullWidth
										label="Plate"
										required
										value={vehicles[selectedVehicleIndex].plate}
										onChange={(e) =>
											handleUpdateVehicle(
												selectedVehicleIndex,
												'plate',
												e.target.value
											)
										}
									/>
								</Grid>
							</Grid>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog}>Done</Button>
					</DialogActions>
				</Dialog>
			</Box>

			<Box
				sx={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					padding: theme.spacing(4),
					borderColor: 'divider',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Button variant="contained" size="large" disabled={!isFormValid()}>
					Submit Application
				</Button>
			</Box>

			<Box sx={{ height: theme.spacing(8) }} />
		</Paper>
	);
};

export default ApplicationForm;
