import { SmallHeader } from '../../components/typography';
import EventCard from '../../components/event-card';
import Grid from '@mui/material/Grid2';
import { Box, Divider } from '@mui/material';
import { listOfEvents } from '../../model/data-mock';
import { IEvent } from '../../model/interfaces';
import { useNavigate } from 'react-router-dom';

interface EventDashboardProps {
	isHomepagePreview?: boolean;
}

export const EventDashboard = ({ isHomepagePreview }: EventDashboardProps) => {
	const navigate = useNavigate();

	function handleRsvp(event: IEvent) {
		navigate(`/event-detail/${event.id}`);
	}

	const listOfEventsToRender = isHomepagePreview
		? listOfEvents.slice(0, 4)
		: listOfEvents;

	return (
		<Box
			component="main"
			sx={{
				mt: 3,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
			}}
		>
			<SmallHeader>Upcoming events</SmallHeader>

			<Grid
				container
				spacing={{ xs: 2, md: 4 }}
				sx={{ display: 'flex', justifyContent: 'center' }}
				columns={12}
			>
				{listOfEventsToRender.map((event) => {
					return (
						<Grid
							key={event.id}
							size={{ xs: 12, sm: 6, md: 6, lg: 3 }}
							sx={{
								flexDirection: 'row',
								justifyContent: 'flex-start',
								display: 'flex',
								position: 'relative',
							}}
						>
							<EventCard
								title={event.title}
								imageUrl={event.imageUrl}
								datetime={event.datetime}
								description={event.shortDescription}
								location={event.location}
								onRsvp={() => handleRsvp(event)}
							/>
						</Grid>
					);
				})}
			</Grid>

			{!isHomepagePreview && (
				<>
					<Divider color="main" sx={{ width: '100%', mt: 5 }} />

					<SmallHeader>Past events</SmallHeader>

					<Grid
						container
						spacing={{ xs: 2, md: 4 }}
						sx={{ display: 'flex', justifyContent: 'center' }}
						columns={12}
					>
						{listOfEvents.map((event) => {
							return (
								<Grid
									key={event.id}
									size={{ xs: 12, sm: 6, md: 6, lg: 3 }}
									sx={{
										flexDirection: 'row',
										justifyContent: 'flex-start',
										display: 'flex',
										position: 'relative',
									}}
								>
									<EventCard
										title={event.title}
										imageUrl={event.imageUrl}
										datetime={event.datetime}
										description={event.shortDescription}
										location={event.location}
										onRsvp={() => handleRsvp(event)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</>
			)}
		</Box>
	);
};
