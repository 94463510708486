import { Button, Box, TextField } from '@mui/material';
import { useState } from 'react';

interface ApplicationsTableActionsProps {
	onAddMemberClick: () => void;
	onBlockSelectedClick: () => void;
	selectedRows: Set<string>;
}

const ApplicationsTableActions = ({
	onAddMemberClick,
	onBlockSelectedClick,
	selectedRows,
}: ApplicationsTableActionsProps) => {
	const [searchTerm, setSearchTerm] = useState('');
	function confirmBlockSelected() {
		onBlockSelectedClick();
	}

	function openAddMemberModal() {
		onAddMemberClick();
	}

	return (
		<Box display="flex" justifyContent="space-between" p={2}>
			<TextField
				variant="outlined"
				placeholder="Search applications..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				sx={{ width: '50%' }}
			/>

			<Box display="flex" gap={2} alignItems="center">
				{selectedRows.size > 0 && (
					<>
						<Button
							variant="contained"
							color="primary"
							onClick={openAddMemberModal}
						>
							Approve selected
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={confirmBlockSelected}
						>
							Reject selected
						</Button>
					</>
				)}
			</Box>
		</Box>
	);
};

export default ApplicationsTableActions;
