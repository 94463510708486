import { Typography } from '@mui/material';

// prettier-ignore
const MorningMeetsLogoLarge = () => {
	return (
		<Typography
			component="h1"
			variant="h3"
			sx={{
				mb: 4,
				fontFamily: 'Times New Roman',
				textAlign: 'left',
				whiteSpace: 'pre',
				fontWeight: 600,
				lineHeight: 'normal',
			}}
		>
			{'Morning\n'}
      {' '} {' '} {' '} {' '} {' '} {' Meets'}
		</Typography>
	);
};

export default MorningMeetsLogoLarge;
