import React from 'react';
import {
	Box,
	IconButton,
	Card,
	Checkbox,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableContainer,
	Typography,
} from '@mui/material';
import { useSelection } from '../../hooks/use-selection';
import { listOfApplications } from '../../model/data-mock';
import ApplicationsTableActions from './applications-table-actions';
import { ThumbDown, ThumbUp } from '@mui/icons-material';

const ApplicationsTable = () => {
	const rowIds = React.useMemo(() => {
		return listOfApplications.map((application) => application.id);
	}, []);

	const { selectAll, deselectAll, selectOne, deselectOne, selected } =
		useSelection(rowIds);

	const selectedSome =
		(selected?.size ?? 0) > 0 &&
		(selected?.size ?? 0) < listOfApplications.length;
	const selectedAll =
		listOfApplications.length > 0 &&
		selected?.size === listOfApplications.length;

	const [tableConfig, setTableConfig] = React.useState({
		page: 0,
		rowsPerPage: 10,
		rowsPerPageOptions: [10, 25, 50],
	});

	function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
		console.log(event.target.checked);
		if (event.target.checked) {
			selectAll();
		} else {
			deselectAll();
		}
	}

	function handleSelectRow(
		event: React.ChangeEvent<HTMLInputElement>,
		rowId: string
	) {
		console.log(rowId);
		if (event.target.checked) {
			selectOne(rowId);
		} else {
			deselectOne(rowId);
		}
	}

	function handlePageChange(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) {
		setTableConfig({ ...tableConfig, page: newPage });
	}

	function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
		setTableConfig({
			...tableConfig,
			rowsPerPage: parseInt(event.target.value, 10),
		});
	}

	function openAddMemberModal() {
		console.log('openAddMemberModal');
	}

	function confirmBlockSelected() {
		console.log('confirmBlockSelected');
	}

	return (
		<Card>
			<ApplicationsTableActions
				onAddMemberClick={openAddMemberModal}
				onBlockSelectedClick={confirmBlockSelected}
				selectedRows={selected}
			/>

			<Divider />

			<Box sx={{ overflowX: 'auto' }}>
				<TableContainer
					sx={{
						width: {
							xs: '400px',
							sm: 'auto',
						},
						minWidth: {
							sm: '1200px',
						},
						overflowX: 'auto',
					}}
				>
					<Table
						sx={{
							tableLayout: 'fixed',
							whiteSpace: 'normal',
							width: '100%',
							display: 'block',
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										checked={selectedAll}
										indeterminate={selectedSome}
										onChange={handleSelectAll}
									/>
								</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Location</TableCell>
								<TableCell>Phone</TableCell>
								<TableCell>Vehicle</TableCell>
								<TableCell>Member reference</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{listOfApplications
								.slice(
									tableConfig.page * tableConfig.rowsPerPage,
									(tableConfig.page + 1) * tableConfig.rowsPerPage
								)
								.map((row) => {
									const isSelected = selected?.has(row.id);

									return (
										<TableRow hover key={row.id} selected={isSelected}>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isSelected}
													onChange={(event) => handleSelectRow(event, row.id)}
												/>
											</TableCell>
											<TableCell>
												<Stack
													sx={{ alignItems: 'center' }}
													direction="row"
													spacing={2}
												>
													<Typography variant="subtitle2">{`${row.firstName} ${row.lastName}`}</Typography>
												</Stack>
											</TableCell>
											<TableCell>{row.email}</TableCell>
											<TableCell>
												{row.city}, {row.country}
											</TableCell>
											<TableCell>{row.phone}</TableCell>
											<TableCell>{`${row.vehicleYear} ${row.vehicleBrand} ${row.vehicleModel}`}</TableCell>
											<TableCell>{`${row.memberReferenceName} (${row.memberReferenceEmail})`}</TableCell>
											<TableCell>{row.status}</TableCell>
											<TableCell>
												<IconButton>
													<ThumbUp color="primary" fontSize="medium" />
												</IconButton>
												<IconButton>
													<ThumbDown color="secondary" fontSize="medium" />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Divider />

			<TablePagination
				component="div"
				count={listOfApplications.length}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleRowsPerPageChange}
				page={tableConfig.page}
				rowsPerPage={tableConfig.rowsPerPage}
				rowsPerPageOptions={tableConfig.rowsPerPageOptions}
			/>
		</Card>
	);
};

export default ApplicationsTable;
