import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	InputLabel,
	OutlinedInput,
	Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IUser } from '../../model/interfaces';

const FormField = ({
	label,
	value,
	name,
}: {
	label: string;
	value: string;
	name: string;
}) => {
	return (
		<Grid size={{ xs: 12, sm: 6 }}>
			<FormControl fullWidth required>
				<InputLabel>{label}</InputLabel>
				<OutlinedInput defaultValue={value} label={label} name={name} />
			</FormControl>
		</Grid>
	);
};

const MemberDataForm = ({ user }: { user: IUser }) => {
	function handleSave(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
	}

	return (
		<Box component="form" onSubmit={handleSave}>
			<Card>
				<CardHeader
					subheader="Please keep your record up to date so we can curate the best events for you!"
					title="Member informations"
				/>

				<Divider />

				<CardContent>
					<Grid container spacing={3}>
						<FormField label="First name" value={user.name} name="firstName" />
						<FormField label="Last name" value={user.name} name="lastName" />
						<FormField label="Email address" value={user.email} name="email" />
						<FormField label="Phone number" value={user.phone} name="phone" />
						<FormField label="City" value={user.city} name="city" />
						<FormField label="Country" value={user.country} name="country" />
					</Grid>
				</CardContent>

				<Divider />

				<CardActions sx={{ justifyContent: 'flex-end' }}>
					<Button variant="contained">Save details</Button>
				</CardActions>
			</Card>
		</Box>
	);
};

export default MemberDataForm;
