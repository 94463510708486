import {
	Card,
	CardMedia,
	Collapse,
	Box,
	useTheme,
	IconButton,
	Chip,
} from '@mui/material';
import { IApplication } from '../../../../model/interfaces';
import ApplicationCardField from './mobile-application-card-field';
import { SmallHeader } from '../../../typography/typography';
import { ThumbUp, ThumbDown } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
interface ApplicationCardProps {
	application: IApplication;
	isExpanded: boolean;
	onToggle: () => void;
	handleApproval: (id: string, status: string) => void;
	handleRejection: (id: string, status: string) => void;
}

const ApplicationCard = ({
	application,
	isExpanded,
	onToggle,
	handleApproval,
	handleRejection,
}: ApplicationCardProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Card
			sx={{
				cursor: 'pointer',
				transition: 'all 0.2s ease-in-out',
				'&:hover': {
					boxShadow: theme.shadows[4],
				},
			}}
		>
			<Box
				sx={{
					backgroundColor: 'background.paper',
					borderBottom: isExpanded
						? `1px solid ${theme.palette.divider}`
						: 'none',
					padding: 2,
				}}
			>
				<Box
					display={{ xs: 'contents', sm: 'flex' }}
					justifyContent="space-between"
					alignItems="flex-start"
				>
					<Box
						onClick={onToggle}
						display="grid"
						sx={{
							gridTemplateColumns: {
								xs: 'repeat(2, 1fr)',
								sm: 'repeat(4, 1fr)',
							},
							gap: 2,
						}}
						flex={1}
					>
						<ApplicationCardField
							label="Name"
							value={`${application.firstName} ${application.lastName}`}
						/>
						<ApplicationCardField
							label="Application Date"
							value={new Date(application.createdAt).toLocaleDateString()}
						/>
						<ApplicationCardField
							label="Vehicle"
							value={`${application.vehicleBrand} ${application.vehicleModel} ${application.vehicleYear}`}
						/>
						<ApplicationCardField
							label="Status"
							value={
								<Chip
									label={application.status}
									color={
										application.status === 'New'
											? 'default'
											: application.status === 'Approved'
											? 'success'
											: application.status === 'Rejected'
											? 'error'
											: application.status === 'Pending payment'
											? 'warning'
											: 'default'
									}
								/>
							}
						/>
					</Box>

					<Box
						display="flex"
						justifyContent={isMobile ? 'space-between' : 'flex-end'}
						minWidth="100px"
					>
						{application.status === 'New' && (
							<>
								<IconButton
									onClick={() => handleApproval(application.id, 'approve')}
								>
									<ThumbUp color="primary" fontSize="medium" />
								</IconButton>

								<IconButton
									onClick={() => handleRejection(application.id, 'reject')}
								>
									<ThumbDown color="secondary" fontSize="medium" />
								</IconButton>
							</>
						)}
					</Box>
				</Box>
			</Box>

			<Collapse in={isExpanded}>
				<Box
					sx={{
						backgroundColor: 'grey.50',
						padding: 2,
					}}
				>
					<Box
						display="grid"
						sx={{
							gridTemplateColumns: {
								xs: '1fr',
								sm: 'repeat(4, 1fr)',
							},
							gap: 2,
						}}
					>
						<ApplicationCardField
							label="Location"
							value={`${application.city}, ${application.country}`}
						/>
						<ApplicationCardField label="Email" value={application.email} />
						<ApplicationCardField label="Phone" value={application.phone} />
						<ApplicationCardField
							label="Member Reference"
							value={`${application.memberReferenceName} (${application.memberReferenceEmail})`}
						/>
					</Box>

					<Box
						position="relative"
						sx={{
							mt: 2,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<SmallHeader>Uploaded Vehicle Picture</SmallHeader>

						<CardMedia
							component="img"
							image={require('../../../../assets/event-card-1.jpg')}
							alt={application.id}
							sx={{
								maxHeight: {
									xs: '200px',
									sm: '300px',
									lg: '500px',
								},
								width: {
									xs: 'inherit',
									lg: '500px',
								},
							}}
						/>
					</Box>
				</Box>
			</Collapse>
		</Card>
	);
};

export default ApplicationCard;
