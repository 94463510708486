import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Stack,
	Typography,
} from '@mui/material';
import { IUser } from '../../model/interfaces';

const MemberProfileCard = ({ user }: { user: IUser }) => {
	return (
		<Card>
			<CardContent>
				<Stack spacing={2} sx={{ alignItems: 'center' }}>
					<Avatar
						src={require('../../assets/profile-avatar.jpg')}
						sx={{ height: '80px', width: '80px' }}
					/>

					<Stack spacing={1} sx={{ textAlign: 'center' }}>
						<Typography variant="h5">{user.name}</Typography>

						<Typography color="text.secondary" variant="body2">
							{user.city} {user.country}
						</Typography>

						<Typography color="text.secondary" variant="body2">
							Member since {user.createdAt}
						</Typography>

						<Typography color="text.secondary" variant="body2">
							Participated in {user.numberOfAttendedEvents} events
						</Typography>
					</Stack>
				</Stack>
			</CardContent>

			<Divider />

			<CardActions>
				<Button fullWidth variant="text">
					Upload picture
				</Button>
			</CardActions>
		</Card>
	);
};

export default MemberProfileCard;
