import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// prettier-ignore
const MorningMeetsLogoSmall = () => {
	const navigate = useNavigate();

	return (
		<Typography
			variant="h6"
			component="div"
			sx={{
				flexGrow: 1,
				fontFamily: 'Times New Roman',
				cursor: 'pointer',
				color: 'black',
				fontSize: '1.5rem',
				textAlign: 'left',
				whiteSpace: 'pre',
				fontWeight: 600,
				lineHeight: 'normal',
			}}
			onClick={() => navigate('/')}
		>
			{'Morning\n'}
      {' '} {' '} {' '} {' '} {' '} {' Meets'}
		</Typography>
	);
};

export default MorningMeetsLogoSmall;
