import PageLayout from '../../components/page-layout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MobileApplications from '../../components/mobile-applications';
import ApplicationsTable from '../../components/applications-table';

const ManageApplicationsPage = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<PageLayout title="Manage applications">
			{isMobile ? <MobileApplications /> : <ApplicationsTable />}
		</PageLayout>
	);
};

export default ManageApplicationsPage;
