import PageLayout from '../../components/page-layout';
import { EventRecordDetail } from '../../components/event-record-detail';
import { useNavigate, useParams } from 'react-router-dom';
import { listOfEvents } from '../../model/data-mock';

const EventRecordPage = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const event = listOfEvents.find((event) => event.id === id);

	if (!event) {
		navigate('/404');
		return null;
	}

	return (
		<PageLayout title={event.title}>
			<EventRecordDetail event={event} />
		</PageLayout>
	);
};

export default EventRecordPage;
