import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './media-gallery.css';

interface MediaGalleryProps {
	imageUrls: string[];
	format: 'square' | 'landscape';
}

const MediaGallery = ({ imageUrls, format }: MediaGalleryProps) => {
	return (
		<Swiper
			spaceBetween={50}
			centeredSlides={true}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			pagination={{
				clickable: false,
			}}
			navigation={true}
			modules={[Autoplay, Pagination, Navigation]}
			className={`swiper-${format}`}
		>
			{imageUrls.map((imageUrl, index) => (
				<SwiperSlide key={index}>
					<img src={imageUrl} alt="Car event" />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default MediaGallery;
