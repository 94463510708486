import PageLayout from '../../components/page-layout';
import { MemberRecordDetail } from '../../components/member-record-detail';

const MyProfilePage = () => {
	return (
		<PageLayout title="My Profile">
			<MemberRecordDetail />
		</PageLayout>
	);
};

export default MyProfilePage;
