import PageLayout from '../../components/page-layout';

const ForgotPasswordPage = () => {
	return (
		<PageLayout
			title="Forgot Password"
			subtext="Enter your email below to reset your password."
		>
			Under construction...
		</PageLayout>
	);
};

export default ForgotPasswordPage;
