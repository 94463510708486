import React from 'react';
import {
	Avatar,
	Box,
	IconButton,
	Card,
	Checkbox,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableContainer,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useSelection } from '../../hooks/use-selection';
import { listOfMembers } from '../../model/data-mock';
import VehicleBrandIcon from '../vehicle-icon/vehicle-brand-icon';
import { Edit, RemoveCircle } from '@mui/icons-material';
import MembersTableActions from './members-table-actions';

const MembersTable = () => {
	const rowIds = React.useMemo(() => {
		return listOfMembers.map((member) => member.id);
	}, []);

	const { selectAll, deselectAll, selectOne, deselectOne, selected } =
		useSelection(rowIds);

	const selectedSome =
		(selected?.size ?? 0) > 0 && (selected?.size ?? 0) < listOfMembers.length;
	const selectedAll =
		listOfMembers.length > 0 && selected?.size === listOfMembers.length;

	const [tableConfig, setTableConfig] = React.useState({
		page: 0,
		rowsPerPage: 10,
		rowsPerPageOptions: [10, 25, 50],
	});

	function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
		console.log(event.target.checked);
		if (event.target.checked) {
			selectAll();
		} else {
			deselectAll();
		}
	}

	function handleSelectRow(
		event: React.ChangeEvent<HTMLInputElement>,
		rowId: string
	) {
		console.log(rowId);
		if (event.target.checked) {
			selectOne(rowId);
		} else {
			deselectOne(rowId);
		}
	}

	function handlePageChange(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) {
		setTableConfig({ ...tableConfig, page: newPage });
	}

	function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
		setTableConfig({
			...tableConfig,
			rowsPerPage: parseInt(event.target.value, 10),
		});
	}

	function openAddMemberModal() {
		console.log('openAddMemberModal');
	}

	function confirmBlockSelected() {
		console.log('confirmBlockSelected');
	}

	return (
		<Card>
			<MembersTableActions
				onAddMemberClick={openAddMemberModal}
				onBlockSelectedClick={confirmBlockSelected}
				selectedRows={selected}
			/>

			<Divider />

			<Box sx={{ overflowX: 'auto' }}>
				<TableContainer
					sx={{
						width: {
							xs: '400px',
							sm: 'auto',
						},
					}}
				>
					<Table
						sx={{
							tableLayout: 'fixed',
							whiteSpace: 'normal',
							width: '100%',
							display: 'block',
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										checked={selectedAll}
										indeterminate={selectedSome}
										onChange={handleSelectAll}
									/>
								</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Location</TableCell>
								<TableCell>Phone</TableCell>
								<TableCell>Member Since</TableCell>
								<TableCell>Events Attended</TableCell>
								<TableCell>Favorite Car Brand</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{listOfMembers
								.slice(
									tableConfig.page * tableConfig.rowsPerPage,
									(tableConfig.page + 1) * tableConfig.rowsPerPage
								)
								.map((row) => {
									const isSelected = selected?.has(row.id);

									return (
										<TableRow hover key={row.id} selected={isSelected}>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isSelected}
													onChange={(event) => handleSelectRow(event, row.id)}
												/>
											</TableCell>
											<TableCell>
												<Stack
													sx={{ alignItems: 'center' }}
													direction="row"
													spacing={2}
												>
													<Avatar
														src={require('../../assets/profile-avatar.jpg')}
													/>
													<Typography variant="subtitle2">
														{row.name}
													</Typography>
												</Stack>
											</TableCell>
											<TableCell>{row.email}</TableCell>
											<TableCell>
												{row.city}, {row.country}
											</TableCell>
											<TableCell>{row.phone}</TableCell>
											<TableCell>
												{dayjs(row.createdAt).format('MMM D, YYYY')}
											</TableCell>
											<TableCell>{row.numberOfAttendedEvents}</TableCell>
											<TableCell>
												<VehicleBrandIcon brand={row.favoriteCarBrand} />
											</TableCell>
											<TableCell>
												<IconButton>
													<RemoveCircle color="primary" fontSize="small" />
												</IconButton>
												<IconButton>
													<Edit color="primary" fontSize="small" />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Divider />

			<TablePagination
				component="div"
				count={listOfMembers.length}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleRowsPerPageChange}
				page={tableConfig.page}
				rowsPerPage={tableConfig.rowsPerPage}
				rowsPerPageOptions={tableConfig.rowsPerPageOptions}
			/>
		</Card>
	);
};

export default MembersTable;
