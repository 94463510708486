import { Drawer, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import GroupsIcon from '@mui/icons-material/Groups';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import EventIcon from '@mui/icons-material/Event';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SidebarItemsList } from '.';

interface SidebarProps {
	isDrawerOpen: boolean;
	setIsDrawerOpen: (isDrawerOpen: boolean) => void;
}

const Sidebar = ({ isDrawerOpen, setIsDrawerOpen }: SidebarProps) => {
	const navigate = useNavigate();
	const auth = getAuth();
	const user = auth.currentUser;
	const isAdmin = user?.email === 'admin@morningmeets.com';

	const mainMenuItems = [
		{ text: 'Events', path: '/events', icon: <EventIcon color="primary" /> },
		{ text: 'Media', path: '/media', icon: <PermMediaIcon color="primary" /> },
		{
			text: 'Driving Routes',
			path: '/driving-routes',
			icon: <DriveEtaIcon color="primary" />,
		},
	];

	const adminMenuItems = [
		{ text: 'Members', path: '/members', icon: <GroupsIcon color="primary" /> },
		{
			text: 'Manage applications',
			path: '/manage-applications',
			icon: <ManageAccountsIcon color="primary" />,
		},
	];

	const bottomMenuItems = [
		{
			text: 'My Profile',
			path: '/my-profile',
			icon: (
				<Avatar
					src={require('../../assets/profile-avatar.jpg')}
					sx={{ height: '24px', width: '24px' }}
				/>
			),
		},
		{
			text: 'Settings',
			path: '/settings',
			icon: <SettingsIcon color="primary" />,
		},
		{ text: 'Logout', path: '/logout', icon: <LogoutIcon color="primary" /> },
	];

	function handleMenuItemClick(path: string) {
		if (path === '/logout') {
			signOut(auth)
				.then(() => {
					navigate('/login');
				})
				.catch((error) => {
					console.error('Error logging out:', error);
				});
		} else {
			navigate(path);
			setIsDrawerOpen(false);
		}
	}

	return (
		<Drawer
			anchor="right"
			open={isDrawerOpen}
			onClose={() => setIsDrawerOpen(false)}
			PaperProps={{
				sx: {
					bgcolor: 'white',
					width: '50%',
					maxWidth: '300px',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<SidebarItemsList
				items={mainMenuItems}
				handleMenuItemClick={handleMenuItemClick}
			/>

			<Divider />

			{isAdmin && (
				<>
					<SidebarItemsList
						items={adminMenuItems}
						handleMenuItemClick={handleMenuItemClick}
						position="bottom"
					/>

					<Divider />
				</>
			)}

			<SidebarItemsList
				items={bottomMenuItems}
				handleMenuItemClick={handleMenuItemClick}
				position="bottom"
			/>
		</Drawer>
	);
};

export default Sidebar;
