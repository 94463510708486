import { Stack } from '@mui/material';
import PageLayout from '../../components/page-layout';
import MembersTable from '../../components/members-table';

const MembersPage = () => {
	return (
		<PageLayout title="Members">
			<Stack spacing={3}>
				<MembersTable />
			</Stack>
		</PageLayout>
	);
};

export default MembersPage;
