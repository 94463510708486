import PageLayout from '../../components/page-layout';

const MediaPage = () => {
	return (
		<PageLayout
			title="Media"
			subtext="Here we will render some information about our media."
		>
			Under construction...
		</PageLayout>
	);
};

export default MediaPage;
