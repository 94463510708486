import { IEvent } from '../../model/interfaces';
import { SmallText, LargeText, SmallHeader } from '../typography';
import MediaGallery from '../media-gallery';
import { Button, Stack, Box } from '@mui/material';

interface EventRecordDetailProps {
	event: IEvent;
}

const EventRecordDetail = ({ event }: EventRecordDetailProps) => {
	const imageUrls = [
		require('../../assets/event-detail-1.jpg'),
		require('../../assets/event-detail-2.jpg'),
	];

	const handleRSVP = () => {
		console.log('RSVP');
	};

	return (
		<Stack gap={2} sx={{ alignItems: 'center' }}>
			<LargeText>{event.fullDescription}</LargeText>

			<MediaGallery imageUrls={imageUrls} format="square" />

			<Box>
				<SmallHeader>Event details</SmallHeader>
				<SmallText>{event.datetime}</SmallText>
				<SmallText>{event.location}</SmallText>
			</Box>

			<Box>
				<SmallHeader>Google Maps Routes</SmallHeader>
				<a
					href="https://maps.app.goo.gl/nS5zUh9bAHMDcv46A"
					target="_blank"
					rel="noreferrer"
				>
					<LargeText>Cruise route part 1</LargeText>
				</a>

				<a
					href="https://maps.app.goo.gl/nS5zUh9bAHMDcv46A"
					target="_blank"
					rel="noreferrer"
				>
					<LargeText>Cruise route part 2</LargeText>
				</a>

				<a
					href="https://maps.app.goo.gl/EBtv7d2yxv1TsqKz7"
					target="_blank"
					rel="noreferrer"
				>
					<LargeText>Cruise route part 3</LargeText>
				</a>
			</Box>

			<Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
				<Box>
					<LargeText>Starting location</LargeText>
					<iframe
						src={
							'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d796.9016505953912!2d-79.9902541!3d43.216998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c85ea7a429fd1%3A0xdfb9e6c7db3da712!2sAncaster%20Town%20Plaza!5e1!3m2!1sen!2sca!4v1731304150733!5m2!1sen!2sca'
						}
						width={'300'}
						height={'300'}
						loading="lazy"
					/>
				</Box>

				<Box>
					<LargeText>Ending location</LargeText>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6531.07352303758!2d-79.156618!3d43.1571205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d35afe66314f87%3A0xa6ec5083e9798e3a!2sClare&#39;s%20Harley-Davidson%20of%20Niagara!5e1!3m2!1sen!2sca!4v1731304469093!5m2!1sen!2sca"
						width="300"
						height="300"
						loading="lazy"
					/>
				</Box>
			</Stack>
			<Button
				variant="contained"
				onClick={handleRSVP}
				sx={{ mt: 2, width: '150px' }}
			>
				RSVP
			</Button>
		</Stack>
	);
};

export default EventRecordDetail;
