interface IVehicleBrandIcon {
	brand: string;
}

const VehicleBrandIcon = ({ brand }: IVehicleBrandIcon) => {
	return (
		<img
			src={require(`../../assets/vehicle-brands/${brand.toLowerCase()}.svg`)}
			style={{ width: '40px', height: '40px' }}
			alt={brand}
		/>
	);
};

export default VehicleBrandIcon;
