import { IUser, IEvent, IApplication } from './interfaces';

export const listOfMembers: IUser[] = [
	{
		id: 'USER001',
		name: 'Enzo Ferrari',
		avatar: '../../assets/profile-avatar.jpg',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'enzo@ferrari.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Ferrari',
	},
	{
		id: 'USER002',
		name: 'Ferruccio Lamborghini',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'ferruccio@lamborghini.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Lamborghini',
	},
	{
		id: 'USER003',
		name: 'Giovanni Agnelli',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'giovanni@agnelli.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Fiat',
	},
	{
		id: 'USER004',
		name: 'Giuseppe Morosini',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'giuseppe@morosini.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Fiat',
	},
	{
		id: 'USER005',
		name: 'Anderson Fueltech',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'anderson@fueltech.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Toyota',
	},
	{
		id: 'USER006',
		name: 'Gazoo Racing',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'gazoo@racing.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Toyota',
	},
	{
		id: 'USER007',
		name: 'Jivo',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'jivo@jivo.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER008',
		name: 'Mizuka Gazoo Racing',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'mizuka@gazoo.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Toyota',
	},
	{
		id: 'USER009',
		name: 'Mizuki Kawashima Gazoo Racing',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'mizuki@kawashima.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Toyota',
	},
	{
		id: 'USER010',
		name: 'Philippe Gilbert Mazda',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'philippe@gilbert.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Mazda',
	},
	{
		id: 'USER011',
		name: 'Franck Montagny Subaru',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'franck@montagny.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Subaru',
	},
	{
		id: 'USER012',
		name: 'Toshihiro Suzuki',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'toshihiro@suzuki.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Suzuki',
	},
	{
		id: 'USER013',
		name: 'Wolfgang Porsche',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'wolfgang@porsche.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER014',
		name: 'Marco Wittmann Porsche',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'marco@wittmann.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER015',
		name: 'Kazuki Nakajima',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kazuki@nakajima.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Hyundai',
	},
	{
		id: 'USER016',
		name: 'Kianta Honda',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kia@kia.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Kia',
	},
	{
		id: 'USER017',
		name: 'Kianan Handa',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kianan@handa.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Mitsubishi',
	},
	{
		id: 'USER018',
		name: 'Kewin Verstraeten',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kevin@verstraeten.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Nissan',
	},
	{
		id: 'USER019',
		name: 'Kieran Gallagher',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kieran@gallagher.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Renault',
	},
	{
		id: 'USER020',
		name: 'Kieran Gallagher',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kieran@gallagher.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Peugeot',
	},
	{
		id: 'USER021',
		name: 'Lewis Hamilton Mercedes',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'lewis@hamilton.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Mercedes Benz',
	},
	{
		id: 'USER022',
		name: 'Toto Wolff',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'hyundai@hyundai.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Mclaren',
	},
	{
		id: 'USER023',
		name: 'Mick Schumacher',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'mick@schumacher.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Lotus',
	},
	{
		id: 'USER024',
		name: 'Kazuki Nakajima',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'kazuki@nakajima.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Mitsubishi',
	},
	{
		id: 'USER025',
		name: 'Loffhagen Stan',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'loffhagen@stan.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER026',
		name: 'Tobias Loffhagen',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'tobias@loffhagen.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Renault',
	},
	{
		id: 'USER027',
		name: 'Sofiane Ouali',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'sofiane@ouali.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER028',
		name: 'Valter Porsche',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'valter@porsche.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER029',
		name: 'Ricardo Porsche',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'ricardo@porsche.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
	{
		id: 'USER030',
		name: 'Richard Porsche',
		avatar: '/assets/avatar.png',
		jobTitle: 'Senior Developer',
		country: 'Canada',
		city: 'Toronto',
		phone: '123-456-7890',
		email: 'richard@porsche.com',
		createdAt: '2024-01-01',
		numberOfAttendedEvents: 10,
		favoriteCarBrand: 'Porsche',
	},
];

export const listOfEvents: IEvent[] = [
	{
		id: 'EVENT001',
		title: 'Cruise For a Cause',
		imageUrl: require('../assets/event-card-1.jpg'),
		shortDescription: `It's time for our final Cruise of the season, and this time, we're hitting the roads for an incredible cause! We’ve aligned with the amazing CityKidz Foundation in Hamilton to host an exciting Toy Drive.`,
		datetime: 'November 16th, 8:00 AM - 12:00 PM',
		location: '73 Wilson St W, Ancaster, ON L9G 1N1',
		fullDescription: `It’s time for our final Cruise of the season, and this time, we're hitting the roads for an incredible cause!We’ve aligned with the amazing CityKidz Foundation in Hamilton to host an exciting Toy Drive. We understand that many of you have begun putting your cars away for the winter but we would like to extend the invite still to join us for socializing`,
	},
	{
		id: 'EVENT002',
		title: 'Muskoka Mission',
		imageUrl: require('../assets/event-card-2.jpg'),
		datetime: 'November 16th, 8:00 AM - 12:00 PM',
		shortDescription: `We're delighted to invite you to join us for a special event!`,
		location: '73 Wilson St W, Ancaster, ON L9G 1N1',
	},
	{
		id: 'EVENT003',
		title: 'Kawartha Mission',
		imageUrl: require('../assets/event-card-3.jpg'),
		datetime: 'November 16th, 8:00 AM - 12:00 PM',
		shortDescription: `Come join us for a drive on Kawarthas most scenic driving roads with a few surprise stops along the way!`,
		location: '73 Wilson St W, Ancaster, ON L9G 1N1',
	},
	{
		id: 'EVENT004',
		title: 'Peterborough Mission',
		imageUrl: require('../assets/event-card-4.jpg'),
		datetime: 'November 16th, 8:00 AM - 12:00 PM',
		shortDescription: `Come join us for a drive through the scenic roads of Peterborough!`,
		location: '73 Wilson St W, Ancaster, ON L9G 1N1',
	},
	{
		id: 'EVENT005',
		title: 'Sunday Drive & Halton Hustle',
		imageUrl: require('../assets/event-card-5.jpg'),
		datetime: 'November 16th, 8:00 AM - 12:00 PM',
		shortDescription: `Come join us for a drive through the scenic roads of Oakville!`,
		location: '73 Wilson St W, Ancaster, ON L9G 1N1',
	},
	{
		id: 'EVENT006',
		title: 'Cars & Coffee at VPX Performance',
		imageUrl: require('../assets/event-card-6.jpg'),
		datetime: 'November 16th, 8:00 AM - 12:00 PM',
		shortDescription: `Come join us for another Cars & Coffee event at VPX Performance!`,
		location: '73 Wilson St W, Ancaster, ON L9G 1N1',
	},
];

export const listOfApplications: IApplication[] = [
	{
		id: 'APPLICATION001',
		createdAt: '2024-01-01',
		email: 'jivonaldo@outlook.com',
		firstName: 'Jivo',
		lastName: 'Naldo',
		phone: '123-456-7890',
		city: 'Toronto',
		province: 'Ontario',
		country: 'Canada',
		vehicleBrand: 'Toyota',
		vehicleModel: 'Supra',
		vehicleYear: 2024,
		vehicleColor: 'Red',
		vehiclePlate: 'ABC123',
		memberReferenceName: 'Enzo Ferrari',
		memberReferenceEmail: 'enzo@ferrari.com',
		status: 'New',
	},
	{
		id: 'APPLICATION002',
		createdAt: '2024-01-01',
		email: 'ferruccio@lamborghini.com',
		firstName: 'Ferruccio',
		lastName: 'Lamborghini',
		phone: '123-456-7890',
		city: 'Toronto',
		province: 'Ontario',
		country: 'Canada',
		vehicleBrand: 'Lamborghini',
		vehicleModel: 'Aventador',
		vehicleYear: 2022,
		vehicleColor: 'Yellow',
		vehiclePlate: 'QU1ET',
		memberReferenceName: 'Enzo Ferrari',
		memberReferenceEmail: 'enzo@ferrari.com',
		status: 'New',
	},
	{
		id: 'APPLICATION003',
		createdAt: '2024-01-01',
		email: 'giovanni@agnelli.com',
		firstName: 'Giovanni',
		lastName: 'Agnelli',
		phone: '123-456-7890',
		city: 'Toronto',
		province: 'Ontario',
		country: 'Canada',
		vehicleBrand: 'Fiat',
		vehicleModel: '500',
		vehicleYear: 2015,
		vehicleColor: 'Red',
		vehiclePlate: 'SMLLER',
		memberReferenceName: 'Enzo Ferrari',
		memberReferenceEmail: 'enzo@ferrari.com',
		status: 'New',
	},
	{
		id: 'APPLICATION004',
		createdAt: '2024-01-01',
		email: 'giuseppe@morosini.com',
		firstName: 'Giuseppe',
		lastName: 'Morosini',
		phone: '123-456-7890',
		city: 'Toronto',
		province: 'Ontario',
		country: 'Canada',
		vehicleBrand: 'Subaru',
		vehicleModel: 'BRZ',
		vehicleYear: 2020,
		vehicleColor: 'Blue',
		vehiclePlate: 'SUBARU',
		memberReferenceName: 'Enzo Ferrari',
		memberReferenceEmail: 'enzo@ferrari.com',
		status: 'Pending payment',
	},
];
