import PageLayout from '../../components/page-layout';

const DrivingRoutesPage = () => {
	return (
		<PageLayout
			title="Driving Routes"
			subtext="Here we will render some information about our scenic and curated routes you can enjoy."
		>
			Under construction...
		</PageLayout>
	);
};

export default DrivingRoutesPage;
