import React from 'react';
import { Typography } from '@mui/material';

interface TypographyProps {
	children: React.ReactNode;
}

export const LargeHeader = ({ children }: TypographyProps) => {
	return (
		<Typography
			variant="h4"
			sx={{
				fontWeight: 600,
				my: 2,
			}}
		>
			{children}
		</Typography>
	);
};

export const SmallHeader = ({ children }: TypographyProps) => {
	return (
		<Typography
			variant="h5"
			component="h5"
			sx={{
				my: 2,
			}}
		>
			{children}
		</Typography>
	);
};

export const LargeText = ({ children }: TypographyProps) => {
	return <Typography variant="body1">{children}</Typography>;
};

export const SmallText = ({ children }: TypographyProps) => {
	return <Typography variant="body2">{children}</Typography>;
};
