import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

interface SidebarItemsListProps {
	items: { text: string; path: string; icon: React.ReactNode }[];
	handleMenuItemClick: (path: string) => void;
	position?: 'top' | 'bottom';
}

const SidebarItemsList = ({
	items,
	handleMenuItemClick,
	position = 'top',
}: SidebarItemsListProps) => {
	const listStyle = position === 'top' ? { flexGrow: 1 } : {};

	return (
		<List sx={listStyle}>
			{items.map((item) => (
				<ListItem
					key={item.text}
					onClick={() => handleMenuItemClick(item.path)}
					sx={{
						textAlign: 'left',
						color: 'black',
						'&:hover': {
							bgcolor: 'transparent',
							cursor: 'pointer',
						},
					}}
				>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText
						primary={item.text}
						sx={{
							'& .MuiListItemText-primary': {
								fontSize: '1.1rem',
								fontWeight: 400,
							},
						}}
					/>
				</ListItem>
			))}
		</List>
	);
};

export default SidebarItemsList;
