import { Button } from '@mui/material';
import EventDashboard from '../../components/event-dashboard';
import PageLayout from '../../components/page-layout';
import { useNavigate } from 'react-router-dom';
import { SmallHeader } from '../../components/typography';
import MediaGallery from '../../components/media-gallery';

const HomePage = () => {
	const navigate = useNavigate();

	const imageUrls = [
		require('../../assets/event-card-1.jpg'),
		require('../../assets/event-card-2.jpg'),
		require('../../assets/event-card-3.jpg'),
		require('../../assets/event-card-4.jpg'),
		require('../../assets/event-card-5.jpg'),
		require('../../assets/event-card-6.jpg'),
	];

	return (
		<PageLayout title="Welcome to our community portal!">
			<SmallHeader>{`We're super excited to have you here!`}</SmallHeader>
			<SmallHeader>
				Our Community portal were created to give you an easy experience of
				finding information about our past and upcoming events, check our
				medias, get in touch with the team, and much more!
			</SmallHeader>

			<MediaGallery imageUrls={imageUrls} format="landscape" />

			<EventDashboard isHomepagePreview />
			<Button
				variant="contained"
				onClick={() => navigate('/events')}
				sx={{ mt: 6 }}
			>
				View all upcoming and past events
			</Button>
		</PageLayout>
	);
};

export default HomePage;
