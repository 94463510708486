import PageLayout from '../../components/page-layout';
import { LargeText, SmallHeader } from '../../components/typography';

const PageNotFound = () => {
	return (
		<PageLayout title="Page Not Found">
			<SmallHeader>{`It looks like you've taken a wrong turn.`}</SmallHeader>

			<LargeText>The page you are looking for does not exist.</LargeText>

			<LargeText>Please check the URL and try again.</LargeText>
		</PageLayout>
	);
};

export default PageNotFound;
