import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getAuth } from 'firebase/auth';
import Sidebar from '../sidebar';
import { LargeHeader } from '../typography';
import { MorningMeetsLogoSmall } from '../morning-meets-logo';
import PageFooter from './page-footer';

interface PageLayoutProps {
	children: React.ReactNode;
	title?: string;
	subtitle?: string;
	subtext?: string;
}

/**
	@description: This is the main layout for the app. It includes the header, sidebar, and main content area. 
	@param {React.ReactNode} children - The content to be rendered within the layout.
	@param {string} title - The title of the page.
	@param {string} subtitle - The subtitle of the page.
	@param {string} subtext - The subtext of the page.
	@returns {React.ReactNode} - The layout component.
*/
const PageLayout = ({ children, title }: PageLayoutProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const auth = getAuth();

	return (
		<>
			<Box sx={{ minHeight: '70vh', bgcolor: 'white' }}>
				<AppBar
					position="sticky"
					sx={{
						bgcolor: 'white',
						boxShadow: 'none',
						px: { xs: 2, sm: 2, md: 8 },
						py: 2,
					}}
				>
					<Toolbar>
						<MorningMeetsLogoSmall />

						{auth.currentUser && (
							<IconButton
								onClick={() => setIsDrawerOpen(true)}
								sx={{ color: 'black' }}
							>
								<MenuIcon />
							</IconButton>
						)}
					</Toolbar>
				</AppBar>

				<Sidebar
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
				/>

				<Box
					component="main"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						textAlign: 'center',
						mx: {
							xs: 2,
							md: 12,
						},
					}}
				>
					<LargeHeader>{title}</LargeHeader>

					{children}
				</Box>
			</Box>

			<Divider color="main" sx={{ width: '100%', mt: 6 }} />

			<PageFooter />
		</>
	);
};

export default PageLayout;
