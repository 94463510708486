import PageLayout from '../../components/page-layout';
import EventDashboard from '../../components/event-dashboard';

const EventsPage = () => {
	return (
		<PageLayout title="Events">
			<EventDashboard />
		</PageLayout>
	);
};

export default EventsPage;
