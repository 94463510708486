import { Box, useMediaQuery, useTheme, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ApplicationForm from '../../components/application-form/application-form';
import JoinOurCommunity from '../../components/join-our-community/join-our-community';

const ApplyPage = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const backgroundImageLeft =
		'url(https://firebasestorage.googleapis.com/v0/b/morning-meets.firebasestorage.app/o/background_group.jpg?alt=media&token=2d6ff490-29e5-4c50-8241-6ee9c3459667)';
	const backgroundImageRight =
		'url(https://firebasestorage.googleapis.com/v0/b/morning-meets.firebasestorage.app/o/background.png?alt=media&token=98d70c55-d539-407c-a018-dee1c8e7e44a)';

	if (isMobile) {
		return (
			<Box
				width="100%"
				height="100vh"
				sx={{
					backgroundAttachment: 'fixed',
					backgroundImage: backgroundImageLeft,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					overflow: 'auto',
				}}
			>
				<Stack width="auto" height="auto" spacing={2} p={2}>
					<JoinOurCommunity />
					<ApplicationForm />
				</Stack>
			</Box>
		);
	}

	return (
		<Box width="100%" height="100vh">
			<Grid container width="inherit" height="inherit">
				<Grid
					size={6}
					sx={{
						backgroundImage: backgroundImageLeft,
						backgroundColor: 'black',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						display: 'flex',
						justifyContent: 'center',
						padding: { xs: 2, lg: 6 },
					}}
					order={{ xs: 2, sm: 1 }}
				>
					<ApplicationForm />
				</Grid>
				<Grid
					size={6}
					order={{ xs: 1, sm: 2 }}
					sx={{
						backgroundImage: backgroundImageRight,
						backgroundColor: 'black',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<JoinOurCommunity />
				</Grid>
			</Grid>
		</Box>
	);
};

export default ApplyPage;
