import { Box, Typography } from '@mui/material';

interface ApplicationCardFieldProps {
	label: string;
	value: string | React.ReactNode;
}

const MobileApplicationCardField = ({
	label,
	value,
}: ApplicationCardFieldProps) => {
	return (
		<Box>
			<Typography color="text.secondary" fontSize="0.75rem" mb="2px">
				{label}
			</Typography>

			<Typography fontSize="0.875rem">{value}</Typography>
		</Box>
	);
};

export default MobileApplicationCardField;
