import { useState, useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import MobileApplicationCard from './components/mobile-application-card/mobile-application-card';
import { listOfApplications as listOfApplicationsMock } from '../../model/data-mock';

const MobileApplications = () => {
	const listOfApplications = listOfApplicationsMock;
	const [searchTerm, setSearchTerm] = useState('');
	const [expandedId, setExpandedId] = useState<string | null>(null);

	const handleApproval = (id: string, status: string) => {
		console.log('Approvals', id, status);
	};

	const handleRejection = (id: string, status: string) => {
		console.log('Rejections', id, status);
	};

	const filteredApplications = useMemo(() => {
		if (!searchTerm) {
			return listOfApplications;
		}

		const searchLower = searchTerm.toLowerCase();

		return listOfApplications.filter((app) => {
			const fields = [
				`${app.firstName} ${app.lastName}`,
				app.email,
				`${app.vehicleBrand} ${app.vehicleModel} ${app.vehicleYear}`,
				app.memberReferenceName,
			];

			return fields.some((field) => field?.toLowerCase().includes(searchLower));
		});
	}, [listOfApplications, searchTerm]);

	return (
		<Box width="100%">
			<TextField
				fullWidth
				variant="outlined"
				placeholder="Search applications..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				sx={{ mb: 2 }}
			/>

			<Box display="flex" flexDirection="column" gap={2}>
				{filteredApplications.map((application) => (
					<MobileApplicationCard
						key={application.id}
						application={application}
						isExpanded={expandedId === application.id}
						onToggle={() =>
							setExpandedId(
								expandedId === application.id ? null : application.id
							)
						}
						handleApproval={handleApproval}
						handleRejection={handleRejection}
					/>
				))}
			</Box>
		</Box>
	);
};

export default MobileApplications;
