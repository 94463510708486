import { Box, Paper, Typography } from '@mui/material';
import { MorningMeetsLogoLarge } from '../morning-meets-logo';
import { useTheme } from '@mui/material/styles';

const JoinOurCommunity = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				height: '100%',
				display: 'flex',
			}}
		>
			<Paper
				elevation={3}
				sx={{
					backgroundColor: 'rgba(255, 255, 255, 0.9)',
					p: theme.spacing(4),
					backdropFilter: 'blur(3px)',
					width: '100%',
					m: 'auto',
				}}
			>
				<MorningMeetsLogoLarge />

				<Typography variant="h4" sx={{ fontWeight: 600, mb: 3 }}>
					Join Our Community
				</Typography>
				<Typography variant="body1" paragraph>
					Become a part of the most exclusive car enthusiast community in
					Ontario.
				</Typography>
				<Typography variant="body1" paragraph>
					• Access to exclusive events
				</Typography>
				<Typography variant="body1" paragraph>
					• Connect with fellow car enthusiasts
				</Typography>
				<Typography variant="body1" paragraph>
					• Share your passion for automobiles
				</Typography>
				<Typography variant="body1" paragraph>
					• Access to all our events through the year
				</Typography>
			</Paper>
		</Box>
	);
};

export default JoinOurCommunity;
