import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
} from 'react-router-dom';
import { AuthProvider, useAuth } from './firebase/auth-context';
import LoginPage from './pages/login-page/login-page';
import HomePage from './pages/home-page/home-page';
import EventsPage from './pages/events-page';
import MembersPage from './pages/members-page';
import MediaPage from './pages/media-page';
import EventRecordPage from './pages/event-record-page';
import DrivingRoutesPage from './pages/driving-routes-page';
import ForgotPasswordPage from './pages/forgot-password-page';
import PageNotFound from './pages/page-not-found';
import MyProfilePage from './pages/my-profile-page';
import SettingsPage from './pages/settings-page';
import ManageApplicationsPage from './pages/manage-applications-page';
import ApplyPage from './pages/apply-page';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { user, loading } = useAuth();

	if (loading) return <div>Loading...</div>;

	return user ? <>{children}</> : <Navigate to="/login" />;
};

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const App = () => {
	return (
		<AuthProvider>
			<Analytics />
			<Router>
				<ScrollToTop />
				<Routes>
					<Route path="/apply" element={<ApplyPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/forgot-password" element={<ForgotPasswordPage />} />
					<Route
						path="/"
						element={
							<PrivateRoute>
								<HomePage />
							</PrivateRoute>
						}
					/>
					<Route path="*" element={<PageNotFound />} />
					<Route
						path="/events"
						element={
							<PrivateRoute>
								<EventsPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/members"
						element={
							<PrivateRoute>
								<MembersPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/media"
						element={
							<PrivateRoute>
								<MediaPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/my-profile"
						element={
							<PrivateRoute>
								<MyProfilePage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/event-detail/:id"
						element={
							<PrivateRoute>
								<EventRecordPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/driving-routes"
						element={
							<PrivateRoute>
								<DrivingRoutesPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/settings"
						element={
							<PrivateRoute>
								<SettingsPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/manage-applications"
						element={
							<PrivateRoute>
								<ManageApplicationsPage />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Router>
		</AuthProvider>
	);
};

export default App;
