import React, { useState } from 'react';
import { auth } from '../../firebase/firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	TextField,
	Button,
	Typography,
	Container,
	Link,
} from '@mui/material';
import { MorningMeetsLogoLarge } from '../../components/morning-meets-logo';
import { PageFooter } from '../../components/page-layout';

const LoginPage = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			await signInWithEmailAndPassword(auth, email, password);
			navigate('/');
		} catch (error) {
			setError('Invalid email or password');
			setPassword('');
		}
	};

	return (
		<>
			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						height: '100vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'white',
					}}
				>
					<MorningMeetsLogoLarge />

					<Box
						component="form"
						onSubmit={handleLogin}
						sx={{
							width: '100%',
							gap: 3,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<TextField
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
							value={email}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setEmail(e.target.value)
							}
						/>

						<TextField
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							value={password}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setPassword(e.target.value)
							}
						/>

						{error && (
							<Typography color="error" align="center">
								{error}
							</Typography>
						)}

						<Button type="submit" variant="contained" color="primary">
							Login
						</Button>

						<Box sx={{ textAlign: 'center' }}>
							<Link
								component="button"
								variant="body2"
								onClick={() => navigate('/forgot-password')}
								sx={{
									color: 'black',
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
							>
								Forgot password?
							</Link>
						</Box>
					</Box>
				</Box>
			</Container>

			<PageFooter />
		</>
	);
};

export default LoginPage;
