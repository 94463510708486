import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MemberProfileCard from './member-profile-card';
import MemberDataForm from './member-data-form';
import { IUser } from '../../model/interfaces';
import { listOfMembers } from '../../model/data-mock';

const user: IUser =
	listOfMembers[Math.floor(Math.random() * (listOfMembers.length - 1))];

const MemberRecordDetail = () => {
	return (
		<Stack spacing={3}>
			<Grid container spacing={3}>
				<Grid size={{ lg: 4, md: 6, xs: 12 }}>
					<MemberProfileCard user={user} />
				</Grid>
				<Grid size={{ lg: 8, md: 6, xs: 12 }}>
					<MemberDataForm user={user} />
				</Grid>
			</Grid>
		</Stack>
	);
};

export default MemberRecordDetail;
