import {
	Typography,
	Card,
	CardMedia,
	CardContent,
	Box,
	useTheme,
} from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface EventCardProps {
	title: string;
	imageUrl: string;
	location: string;
	description: string;
	datetime: string;
	onRsvp: () => void;
}

const EventCard = ({
	title,
	imageUrl,
	description,
	location,
	datetime,
	onRsvp,
}: EventCardProps) => {
	const theme = useTheme();

	return (
		<Card
			onClick={onRsvp}
			sx={{
				cursor: 'pointer',
				transition: 'all 0.2s ease-in-out',
				'&:hover': {
					boxShadow: theme.shadows[4],
				},
				height: {
					xs: 'inherit',
					sm: '450px',
					lg: '500px',
				},
				width: {
					xs: 'inherit',
					sm: '100%',
				},
			}}
		>
			<CardMedia
				sx={{
					minHeight: 140,
					aspectRatio: '16 / 9',
					position: 'relative',
				}}
				image={imageUrl}
				title={title}
			/>

			<CardContent sx={{ height: '100%' }}>
				<Typography gutterBottom variant="h5" component="div">
					{title}
				</Typography>
				<Typography variant="body2" sx={{ color: 'text.secondary' }}>
					{description}
				</Typography>

				<Box
					position={{
						xs: 'initial',
						sm: 'absolute',
					}}
					bottom={10}
					sx={{
						mt: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
					}}
				>
					<Typography
						variant="body2"
						sx={{ color: 'text.secondary' }}
						display="flex"
						alignItems="center"
					>
						<CalendarMonthOutlinedIcon sx={{ fontSize: 16, mr: 1 }} />
						{datetime}
					</Typography>

					<Typography
						variant="body2"
						sx={{ color: 'text.secondary' }}
						display="flex"
						alignItems="center"
					>
						<FmdGoodOutlinedIcon sx={{ fontSize: 16, mr: 1 }} />
						{location}
					</Typography>

					<Typography
						variant="body2"
						sx={{ color: 'text.secondary' }}
						display="flex"
						alignItems="center"
						mt={2}
					>
						<ArrowForwardIcon sx={{ fontSize: 16, mr: 1 }} />
						See more
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default EventCard;
